//数据字典处理
import {
    propertyService
} from '@/api/utils/request'
const apiUrl = {
    GetTrainingList: "/api/training/gets",
}
const trainingApi = {
    //获取网格长列表
    GetTrainingList(parameter) {
        return propertyService({
            url: apiUrl.GetTrainingList,
            method: 'get',
            params: parameter,
            needToken: true
        })
    },
}
export {
    trainingApi
}